/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* ================================
   ~Global Elements
   ================================ */
.select2-dropdown .select2-results {
  font-size: 1.3rem; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/**
 * Navigation
 */
#primary-nav ul li.level-1 {
  width: 20%;
  white-space: nowrap;
  padding: 1rem 0rem;
  min-width: 14%;
  font-size: 12.5px; }
  @media screen and (min-width: 992px) {
    #primary-nav ul li.level-1 {
      font-size: 15px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav ul li.level-1 {
      font-size: 16px; } }
  #primary-nav ul li.level-1 .active-trail {
    color: #B31B1B; }

/* ================================
   ~Base Elements
   ================================ */
.panel-em-home {
  /* -- beginning of FAQ -- */ }
  .panel-em-home #top-3col-left .panel-pane {
    margin-bottom: 3rem; }
  .panel-em-home #top-3col-center .cycle-slideshow {
    border: none;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .panel-em-home #top-3col-center .cycle-slideshow {
        width: 65%;
        margin: 0 auto; } }
    @media screen and (min-width: 992px) {
      .panel-em-home #top-3col-center .cycle-slideshow {
        width: 100%; } }
    .panel-em-home #top-3col-center .cycle-slideshow .cycle-prev {
      position: absolute;
      top: 42%;
      left: 0; }
    .panel-em-home #top-3col-center .cycle-slideshow .cycle-next {
      position: absolute;
      top: 42%;
      right: 0; }
  .panel-em-home #top-3col-center .work-request {
    margin: 0 0 1rem 0;
    padding: 2rem 0 1rem 0; }
    .panel-em-home #top-3col-center .work-request .btn--wcm {
      width: 27rem;
      height: 5rem;
      padding: 0.75rem 2rem;
      border-radius: 3rem;
      font-size: 1.3rem;
      color: #fff;
      background-color: #e87722;
      border-color: #cf4520; }
      @media screen and (min-width: 992px) {
        .panel-em-home #top-3col-center .work-request .btn--wcm {
          font-size: 1.7rem;
          width: 30rem;
          height: 5.5rem; } }
      @media screen and (min-width: 1200px) {
        .panel-em-home #top-3col-center .work-request .btn--wcm {
          font-size: 1.9rem;
          width: 35rem; } }
      .panel-em-home #top-3col-center .work-request .btn--wcm:hover {
        background-color: #ffc72c;
        border-color: #cf4520; }
      .panel-em-home #top-3col-center .work-request .btn--wcm:before {
        top: 0.5rem;
        font-size: 102%; }
  .panel-em-home #top-3col-right {
    /* -- end of FAQ -- */ }
    .panel-em-home #top-3col-right .pane-faq-panel-pane-2 {
      position: relative;
      margin-bottom: 1.2rem;
      height: 100%;
      min-height: 5rem;
      overflow: hidden; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 #edit-category {
        margin-top: 0.8em;
        margin: 0.6rem 2.2rem -0.6rem 2.2rem; }
        @media screen and (min-width: 768px) {
          .panel-em-home #top-3col-right .pane-faq-panel-pane-2 #edit-category {
            width: 180px;
            margin: 0.6rem 3.2rem -0.6rem 3.2rem; } }
        @media screen and (min-width: 992px) {
          .panel-em-home #top-3col-right .pane-faq-panel-pane-2 #edit-category {
            width: 220px; } }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .lt-ie9 .faq-widget .views-submit-button {
        visibility: hidden; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .views-row-faq-widget {
        display: none; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .views-row-first .views-row-faq-widget {
        display: block; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .info-button {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 10px;
        bottom: 10px;
        background: url(../images/photo-icon.png);
        z-index: 101;
        cursor: pointer;
        opacity: 0.8;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .image-viewer-widget .cycle-prev {
        position: absolute;
        top: 8.5em;
        left: 0; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .image-viewer-widget .cycle-next {
        position: absolute;
        top: 8.5em;
        right: 0; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .ie8 .image-viewer-widget img {
        width: auto;
        height: 244px; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .q-and-a .element__hide {
        padding: 0;
        display: block !important; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .q-and-a .element__toggle {
        color: #000; }
        .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .q-and-a .element__toggle:hover {
          background: none;
          color: #000; }
        .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .q-and-a .element__toggle:after {
          display: none; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .q-and-a .question,
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .q-and-a .answer {
        margin: 1rem;
        padding: 0 2.2rem;
        font-size: 1.4rem;
        border: none; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .q-and-a .answer > p {
        margin: 0;
        padding: 0; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .view-empty {
        padding: 1rem 2rem; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .select2-container {
        width: 26rem !important;
        margin: 0.6rem 2.2rem -0.6rem 2.2rem; }
        @media screen and (min-width: 768px) {
          .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .select2-container {
            width: 31.2rem !important;
            margin: 0.6rem 3.2rem -0.6rem 3.2rem; } }
        .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .select2-container .select2-selection__rendered {
          font-size: 1.3rem; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .cycle-slideshow .cycle-prev {
        position: absolute;
        top: 1rem;
        left: 0; }
      .panel-em-home #top-3col-right .pane-faq-panel-pane-2 .cycle-slideshow .cycle-next {
        position: absolute;
        top: 1rem;
        right: 0; }
    .panel-em-home #top-3col-right .pane-downloads-panel-pane-1 ul {
      list-style: outside none none;
      padding: 1.4rem; }
  .panel-em-home .facilities-title-pane .facilities-title-item {
    background: #666666; }
    .panel-em-home .facilities-title-pane .facilities-title-item .facilities-title {
      display: inline-block;
      margin-bottom: 1.2rem;
      padding: 0 2rem;
      color: #fff;
      font-family: "1898Sans-Regular", sans-serif;
      font-size: 2.4rem; }
    .panel-em-home .facilities-title-pane .facilities-title-item .wcmc-cta__facilities {
      float: right;
      font-size: 1.2rem;
      margin-top: 1rem;
      margin-right: 1.8rem; }
      .panel-em-home .facilities-title-pane .facilities-title-item .wcmc-cta__facilities .cta-icon {
        width: 38px;
        height: 38px;
        fill: #fff;
        color: #fff; }
  .panel-em-home .facilities-title-pane:after:before, .panel-em-home .facilities-title-pane:after:after {
    content: " ";
    display: table; }
  .panel-em-home .facilities-title-pane:after:after {
    clear: both; }
  .panel-em-home .cycle-slideshow {
    background: #f7f7f7 !important; }
    .panel-em-home .cycle-slideshow .cycle-prev,
    .panel-em-home .cycle-slideshow .cycle-next {
      width: 20px;
      height: 40px;
      opacity: 0.6;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
      cursor: pointer; }
    .panel-em-home .cycle-slideshow .cycle-prev {
      z-index: 101;
      background: url(../images/arrow_left.png) #e87722 no-repeat center center; }
    .panel-em-home .cycle-slideshow .cycle-next {
      z-index: 101;
      background: url(../images/arrow_right.png) #e87722 no-repeat center center; }
    .panel-em-home .cycle-slideshow .cycle-prev:hover,
    .panel-em-home .cycle-slideshow .cycle-next:hover {
      opacity: 0.9;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"; }
  .panel-em-home .pane-downloads-panel-pane-1,
  .panel-em-home .pane-faq-panel-pane-2 {
    border: 1px solid #dddddd;
    background-color: #f7f7f7; }
    .panel-em-home .pane-downloads-panel-pane-1 h3,
    .panel-em-home .pane-faq-panel-pane-2 h3 {
      background-color: #4a5157;
      display: block;
      padding: 0.7em 0.6em 0.9em 0.8em;
      color: #ffffff;
      text-transform: uppercase; }
    .panel-em-home .pane-downloads-panel-pane-1 p,
    .panel-em-home .pane-faq-panel-pane-2 p {
      padding: 0em 1.4em 0em 1.4em; }
    .panel-em-home .pane-downloads-panel-pane-1 .wcmc-button,
    .panel-em-home .pane-faq-panel-pane-2 .wcmc-button {
      border: .2em solid #ffffff;
      text-decoration: none;
      color: #ffffff;
      font-weight: normal;
      text-align: center; }
      .panel-em-home .pane-downloads-panel-pane-1 .wcmc-button:hover,
      .panel-em-home .pane-faq-panel-pane-2 .wcmc-button:hover {
        background: #00355F; }
      .panel-em-home .pane-downloads-panel-pane-1 .wcmc-button p a[href^="http"]:after,
      .panel-em-home .pane-faq-panel-pane-2 .wcmc-button p a[href^="http"]:after {
        display: none; }
  .panel-em-home .callout {
    border: 1px solid #dddddd;
    background-color: #f7f7f7; }
    .panel-em-home .callout h3 {
      background-color: #4a5157;
      display: block;
      padding: 0.7em 0.6em 0.9em 0.8em;
      color: #ffffff;
      text-transform: uppercase; }
    .panel-em-home .callout p {
      padding: 0em 1.4em 0em 1.4em; }

.panel-hero-3col .view-display-id-panel_pane_3 #edit-field-category-tid-wrapper .views-widget .select2-container {
  width: 32rem !important; }

.panel-hero-3col .pane-staff-directory-panel-pane-1 .view-content .staff-member {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  clear: both;
  display: inline-block;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .panel-hero-3col .pane-staff-directory-panel-pane-1 .view-content .staff-member .staff-image {
      float: left;
      width: 15%; } }
  .panel-hero-3col .pane-staff-directory-panel-pane-1 .view-content .staff-member .staff-image img {
    width: 14rem;
    height: auto;
    max-width: 14rem;
    vertical-align: bottom; }
  .panel-hero-3col .pane-staff-directory-panel-pane-1 .view-content .staff-member .views-field-nothing {
    margin-top: 0; }
    @media screen and (min-width: 768px) {
      .panel-hero-3col .pane-staff-directory-panel-pane-1 .view-content .staff-member .views-field-nothing {
        width: 78%;
        float: right; } }
    @media screen and (min-width: 992px) {
      .panel-hero-3col .pane-staff-directory-panel-pane-1 .view-content .staff-member .views-field-nothing {
        width: 79.2%; } }
    @media screen and (min-width: 1200px) {
      .panel-hero-3col .pane-staff-directory-panel-pane-1 .view-content .staff-member .views-field-nothing {
        width: 81%; } }
  .panel-hero-3col .pane-staff-directory-panel-pane-1 .view-content .staff-member + h3 {
    border-top: 1px solid #ddd;
    padding-top: 2.4rem; }

.information-column .pane-map-this-pane {
  width: 100%;
  margin: 3rem auto 3rem auto; }
  .information-column .pane-map-this-pane .map-this-location {
    text-align: center; }
    .information-column .pane-map-this-pane .map-this-location .wcm-cta__map-this-sidebar {
      -webkit-transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      transform: translate(0%, 0%); }
      .information-column .pane-map-this-pane .map-this-location .wcm-cta__map-this-sidebar .cta-icon {
        margin-left: -1.4rem !important;
        font-size: 3.8rem;
        color: #b31b1b;
        fill: #e97623; }
      .information-column .pane-map-this-pane .map-this-location .wcm-cta__map-this-sidebar > span {
        position: relative;
        bottom: 12px;
        left: 8px;
        color: #b31b1b;
        font-size: 1.3rem; }
        .information-column .pane-map-this-pane .map-this-location .wcm-cta__map-this-sidebar > span:hover {
          color: #e87722; }

.information-column .fieldable-panels-pane .work-request {
  color: #fff;
  background-color: #e87722;
  border-color: #cf4520; }
  .information-column .fieldable-panels-pane .work-request:hover {
    background-color: #ffc72c;
    border-color: #cf4520; }
  .information-column .fieldable-panels-pane .work-request:before {
    top: 1px; }

footer .footer-contact .footer-contact__site-name a {
  font-size: 15px; }
  @media screen and (min-width: 992px) {
    footer .footer-contact .footer-contact__site-name a {
      font-size: 18px; } }

footer .footer-site .footer-address {
  border-right: none; }
  @media screen and (min-width: 992px) {
    footer .footer-site .footer-address {
      width: 18% !important; } }

footer .footer-site .footer-nav {
  margin-bottom: 2rem;
  border-left: 1px solid #DDD; }
  @media screen and (min-width: 992px) {
    footer .footer-site .footer-nav {
      width: 78% !important; } }
  footer .footer-site .footer-nav .block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    -ms-column-gap: 2rem;
    -o-column-gap: 2rem;
    column-gap: 2rem; }
    footer .footer-site .footer-nav .block ul.menu {
      width: 85%; }

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

@import "mixins";

/* ================================
   ~Global Elements
   ================================ */
.select2-dropdown {
  .select2-results {
    font-size: 1.3rem;
  }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

/**
 * Navigation
 */
#primary-nav {
  ul {
    li.level-1 {
      width: 20%;
      white-space: nowrap;
      padding: 1rem 0rem;
      min-width: 14%;
      font-size: 12.5px;
      @include breakpoint($md) {
        font-size: 15px;
      }
      @include breakpoint($lg) {
        font-size: 16px;
      }
      .active-trail {
        color: #B31B1B;
      }
    }
  }
}

/* ================================
   ~Base Elements
   ================================ */
.panel-em-home {
  #top-3col-left {
    .panel-pane {
      margin-bottom: 3rem;
      //.pane-title {
      //  margin: 0;
      //}
    }
  }
  #top-3col-center {
    .cycle-slideshow {
      border: none;
      width: 100%;
      @include breakpoint($sm) {
        width: 65%;
        margin: 0 auto;
      }
      @include breakpoint($md) {
        width: 100%;
      }
      .cycle-prev {
        position: absolute;
        top: 42%;
        left: 0;
      }
      .cycle-next {
        position: absolute;
        top: 42%;
        right: 0;
      }
    }
    .work-request {
      margin: 0 0 1rem 0;
      padding: 2rem 0 1rem 0;
      .btn--wcm {
        width: 27rem;
        height: 5rem;
        padding: 0.75rem 2rem;
        border-radius: 3rem;
        font-size: 1.3rem;

        color: #fff;
        background-color: #e87722;
        border-color: #cf4520;

        @include breakpoint($md) {
          font-size: 1.7rem;
          width: 30rem;
          height: 5.5rem;
        }
        @include breakpoint($lg) {
          font-size: 1.9rem;
          width: 35rem;
        }
        &:hover {
          //color: #cf4520;
          background-color: #ffc72c;
          border-color: #cf4520;
        }
        &:before {
          //left: 88%;
          top: 0.5rem;
          font-size: 102%;
        }
      }
    }
  }
  /* -- beginning of FAQ -- */
  #top-3col-right {
    .pane-faq-panel-pane-2 {
      position: relative;
      margin-bottom: 1.2rem;
      height: 100%;
      min-height: 5rem;
      overflow: hidden;
      #edit-category {
        margin-top: 0.8em;
        margin: 0.6rem 2.2rem -0.6rem 2.2rem;
        @include breakpoint($sm) {
          width: 180px;
          margin: 0.6rem 3.2rem -0.6rem 3.2rem;
        }
        @include breakpoint($md) {
          width: 220px;
        }
      }
      .lt-ie9 .faq-widget .views-submit-button {
        visibility: hidden;
      }
      .views-row-faq-widget {
        display: none;
      }
      .views-row-first .views-row-faq-widget {
        display: block;
      }
      .info-button {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 10px;
        bottom: 10px;
        background: url(../images/photo-icon.png);
        z-index: 101;
        cursor: pointer;
        opacity: 0.8;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      }
      .image-viewer-widget .cycle-prev {
        position: absolute;
        top: 8.5em;
        left: 0;
      }
      .image-viewer-widget .cycle-next {
        position: absolute;
        top: 8.5em;
        right: 0;
      }
      .ie8 .image-viewer-widget img {
        width: auto;
        height: 244px;
      }
      .q-and-a {
        .element__hide {
          padding: 0;
          display: block !important;
        }
        .element__toggle {
          color: #000;
          &:hover {
            background: none;
            color: #000;
          }
          &:after {
            display: none;
          }
        }
        .question,
        .answer {
          margin: 1rem;
          padding: 0 2.2rem;
          font-size: 1.4rem;
          border: none;
        }
        .answer > p {
          margin: 0;
          padding: 0;
        }
      }
      .view-empty {
        padding: 1rem 2rem;
      }
      .select2-container {
        width: 26rem !important;
        margin: 0.6rem 2.2rem -0.6rem 2.2rem;
        @include breakpoint($sm) {
          width: 31.2rem !important;
          margin: 0.6rem 3.2rem -0.6rem 3.2rem;
        }
        .select2-selection__rendered {
          font-size: 1.3rem;
        }
      }
      .cycle-slideshow {
        .cycle-prev {
          position: absolute;
          top: 1rem;
          left: 0;
        }
        .cycle-next {
          position: absolute;
          top: 1rem;
          right: 0;
        }
      }
    }
    /* -- end of FAQ -- */
    .pane-downloads-panel-pane-1 {
      ul {
        list-style: outside none none;
        padding: 1.4rem;
      }
    }
  }
  .facilities-title-pane {
    .facilities-title-item {
      background: $wcm-med-gray;
      .facilities-title {
        display: inline-block;
        margin-bottom: 1.2rem;
        padding: 0 2rem;
        color: #fff;
        font-family: $wcm-regular;
        font-size: 2.4rem;
      }
      .wcmc-cta__facilities {
        float: right;
        font-size: 1.2rem;
        margin-top: 1rem;
        margin-right: 1.8rem;
        .cta-icon {
          width: 38px;
          height: 38px;
          fill: #fff;
          color: #fff;
        }
      }
    }
    &:after {
      @include clearfix;
    }
  }

  .cycle-slideshow {
    background: $wcm-bg-gray !important;
    .cycle-prev,
    .cycle-next {
      width: 20px;
      height: 40px;
      opacity: 0.6;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
      cursor: pointer;
    }
    .cycle-prev {
      z-index: 101;
      background: url(../images/arrow_left.png) #e87722 no-repeat center center;
    }
    .cycle-next {
      z-index: 101;
      background: url(../images/arrow_right.png) #e87722 no-repeat center center;
    }
    .cycle-prev:hover,
    .cycle-next:hover {
      opacity: 0.9;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
  }
  .pane-downloads-panel-pane-1,
  .pane-faq-panel-pane-2 {
    border: 1px solid $wcm-border-gray;
    background-color: $wcm-bg-gray;
    h3 {
      background-color: #4a5157;
      display: block;
      padding: 0.7em 0.6em 0.9em 0.8em;
      color: #ffffff;
      text-transform: uppercase;
    }
    p {
      padding: 0em 1.4em 0em 1.4em;
    }
    .wcmc-button {
      border: .2em solid #ffffff;
      text-decoration: none;
      color: #ffffff;
      font-weight: normal;
      text-align: center;
      &:hover {
        background: #00355F;
      }
      p a[href^="http"]:after {
        display: none;
      }
    }
  }
  .callout {
    border: 1px solid $wcm-border-gray;
    background-color: $wcm-bg-gray;
    //-webkit-box-shadow: 0px 1px 3px rgba(50, 50, 50, 0.3);
    //-moz-box-shadow: 0px 1px 3px rgba(50, 50, 50, 0.3);
    //box-shadow: 0px 1px 3px rgba(50, 50, 50, 0.3);
    h3 {
      background-color: #4a5157;
      display: block;
      padding: 0.7em 0.6em 0.9em 0.8em;
      color: #ffffff;
      text-transform: uppercase;
    }
    p {
      padding: 0em 1.4em 0em 1.4em;
    }
  }
  //.callout .wcmc-button {
  //  border: .2em solid #ffffff;
  //  text-decoration: none;
  //  color: #ffffff;
  //  font-weight: normal;
  //  text-align: center;
  //}
  //.callout .wcmc-button:hover {
  //  background: #00355F;
  //}
  //.callout p a[href^="http"]:after {
  //  display: none;
  //}
}

.panel-hero-3col {
  .view-display-id-panel_pane_3 {
    #edit-field-category-tid-wrapper {
      //label {
      //  float: left;
      //  margin: 1rem 1.5rem 2rem 0;
      //}
      .views-widget {
        .select2-container {
          width: 32rem !important;
        }
      }
    }
  }
  .pane-staff-directory-panel-pane-1 {
    .view-content {
      .staff-member {
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        clear: both;
        display: inline-block;
        width: 100%;
        .staff-image {
          @include breakpoint($sm) {
            float: left;
            width: 15%;
          }
          img {
            width: 14rem;
            height: auto;
            max-width: 14rem;
            vertical-align: bottom;
          }
        }
        .views-field-nothing {
          margin-top: 0;
          @include breakpoint($sm) {
            width: 78%;
            float: right;
          }
          @include breakpoint($md) {
            width: 79.2%;
          }
          @include breakpoint($lg) {
            width: 81%;
          }
        }
        & + h3 {
          border-top: 1px solid #ddd;
          padding-top: 2.4rem;
        }
      }
    }
  }
}


.information-column {
  .pane-map-this-pane {
    //width: 255px;
    width: 100%;
    margin: 3rem auto 3rem auto;
    .map-this-location {
      text-align: center;
      // border-bottom: 1px solid #dddddd;
      .wcm-cta__map-this-sidebar {
        -webkit-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        .cta-icon {
          margin-left: -1.4rem !important;
          font-size: 3.8rem;
          color: #b31b1b;
          fill: #e97623;
        }
        > span {
          position: relative;
          bottom: 12px;
          left: 8px;
          color: #b31b1b;
          font-size: 1.3rem;
          &:hover {
            color: #e87722;
          }
        }
      }
    }
  }
  .fieldable-panels-pane {
    .work-request {
      color: #fff;
      background-color: #e87722;
      border-color: #cf4520;
      &:hover {
        //color: #cf4520;
        background-color: #ffc72c;
        border-color: #cf4520;
      }
      &:before {
        top: 1px;
      }
    }
  }

}

footer {
  .footer-contact {
    .footer-contact__site-name a {
      font-size: 15px;
      @include breakpoint($md) {
        font-size: 18px;
      }
    }
  }
  .footer-site {
    .footer-address {
      border-right: none;
      @include breakpoint($md) {
        width: 18% !important;
      }
    }
    .footer-nav {
      margin-bottom: 2rem;
      border-left: 1px solid #DDD;
      @include breakpoint($md) {
        width: 78% !important;
      }
      .block {
        @include facilities-multi-column;
        @include facilities-multi-gap;
        ul.menu {
          width: 85%;
        }
      }
    }
  }
}
